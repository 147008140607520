.home-tab-container {
  font-weight: 500;
  margin: 8px 0;
  border-radius: 8px;
  overflow: hidden;
  height: 50px;

  .ant-tabs {
    height: 50px;
  }

  .ant-tabs-nav {
    margin-bottom: 0;
    border-radius: 8px !important;
    overflow: hidden !important;
    height: 50px;
  }

  .ant-tabs-tab {
    padding: 0;
    align-items: flex-start;
  }

  .ant-tabs-content-holder {
    padding: 0;
  }

  .category-image {
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-bottom: 4px;
  }

  .category-game {
    display: flex;
    align-items: center;
    width: 85px; /* Adjust the width as needed */
    height: 30px;
    color: black;
    margin: 2px;
  }

  .category-image {
    width: 30px;
    height: 30px;
    margin: 5px;
  }

  // .ant-tabs-nav-wrap {
  //     padding-left: 1rem;
  // }
  .ant-tabs-nav-wrap::after {
    width: 30px;
    height: 40px;
  }
  @media (max-width: 767px) {
    /* Mobile view */
    .category-game {
      width: 52px; /* Adjust the width as needed */
      height: 20px;
      font-size: 12px;
      margin: 5px;
    }

    .category-image {
      // width: 20px;
      // height: 22px;
      margin: 3px;
    }

    :where(.css-dev-only-do-not-override-w8mnev).ant-tabs
      .ant-tabs-tab
      + .ant-tabs-tab {
      margin: 0 0 0 0px;
    }

    :where(.css-dev-only-do-not-override-12upa3x).ant-tabs
      .ant-tabs-tab
      + .ant-tabs-tab {
      margin: 0 0 0 0px;
    }

    :where(.css-1wazalj).ant-tabs .ant-tabs-tab + .ant-tabs-tab {
      margin: 0 0 0 0px;
    }

    .home-tab-container .ant-tabs-tab {
      padding: 12px 0;
    }

    .home-tab-container {
      padding: 12px 0;
    }
    :where(.css-dev-only-do-not-override-1wazalj).ant-tabs
      .ant-tabs-tab
      + .ant-tabs-tab {
      margin-left: 20px;
    }
    .ant-tabs-tab {
      padding: 12px 0;
    }
  }
}


.provider-title {
  text-align: center;
  color: transparent !important;
  background: linear-gradient(45deg, #85c1e9, #a9ccea); /* Lighter blue gradient */
  -webkit-background-clip: text;
  background-clip: text;
  text-transform: uppercase;
  letter-spacing: 0.6rem !important;
  margin-left: 0.4rem !important;
  font-weight: 900;
  margin-top: 12px;
}

.letter {
  animation: glow 2s ease-in-out infinite alternate;
}

@keyframes glow {
  0% {
    text-shadow: 0 0 10px rgba(133, 193, 233, 0.8),
      0 0 20px rgba(133, 193, 233, 0.8), 0 0 30px rgba(133, 193, 233, 0.8); /* Lighter text shadow */
  }
  100% {
    text-shadow: 0 0 20px rgba(169, 204, 234, 1),
      0 0 40px rgba(169, 204, 234, 0.8), 0 0 60px rgba(169, 204, 234, 0.6); /* Lighter text shadow */
  }
}

.underline-box {
  display: flex;
  justify-content: center;
  column-gap: 1rem;
  margin-bottom: 12px;
  margin-top: 8px;
}

.underline-box .left {
  height: 2px;
  background: #85c1e9; /* Lighter blue color */
  flex-basis: 30%;
}

.underline-box .right {
  height: 2px;
  background: #85c1e9; /* Lighter blue color */
  flex-basis: 30%;
}

.ant-modal-content {
  position: relative;
    background-color: #FFFFFF;
    background-clip: padding-box;
    border: 0;
    border-radius: 8px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    pointer-events: auto;
    padding: 20px 24px;
}
.ant-modal-content h4{
  font-weight: bold;
}
.ant-tabs-tab-btn {
  padding: 8px;
  font-weight: 300;
  color: #ccc;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  border-radius: 4px;
  /* background: rgb(205, 159, 21); */
}

.antd-notices-modal.ant-modal .ant-modal-footer {
  margin-top: 0px;
}
.antd-notices-modal.ant-modal .ant-modal-content {
  padding: 0px !important;
}
.antd-notices-modal.ant-modal {
  transform: translateY(-50%) !important;
  top: 50%;
}
.antd-promotion-modal.ant-modal {
  transform: translateY(-50%) !important;
  top: 50%;
}