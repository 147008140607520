body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-weight: 100;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#root {
  max-width: 500px;
  min-height: 100vh;
  margin: auto;
  background-color: #171819;
}

.app-container {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  padding: 0 15px;
}
.home-container{
  padding-bottom: 0rem;
}

.home-container .home-promo-item {
  max-height: 200px;
  margin: 0 1rem;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 12px 12px rgba(0, 0, 0, 0.3);
}

.home-container .slider {
  padding-bottom: 2.2rem !important;
}

.home-promo-item img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.dark-bg {
  background-color: #171819;
}

.white-bg {
  background-color: white;
}

.dark-content-bg {
  background-color: #0f1117;
}

.opacity-disable {
  opacity: 0.7;
}

.light-bg {
  background-image: url(./images/f8_bg.png);
  /* background-color: white; */
  background-size: cover;
  background-repeat: repeat-y;
}

html {
  scroll-behavior: smooth;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

a:hover {
  text-decoration: none;
}

.pr-10 {
  padding-right: 10px;
}

.my-10 {
  margin: 10px 0;
}

.ml-10 {
  margin-left: 10px !important;
}

.pt-80 {
  padding-top: 80px;
}

.mt-80 {
  margin-top: 80px;
}

.pt-200 {
  padding-top: 200px;
}

.pl-0 {
  padding-left: 0 !important;
}

.-ml-15 {
  margin-right: -15px !important;
}

.h-40 {
  height: 40px !important;
}

.verify-code {
  width: 60px;
  height: 60px;
  font-size: 25px !important;
  font-weight: bold;
  text-align: center;
}

.nav-button {
  border: none;
  z-index: 99;
}

.header-container {
  position: fixed;
  max-width: 500px;
  width: calc(100% - 0px);
  margin: auto;
  height: 50px;
  /* position: fixed; */
  display: flex;
  align-items: center;
  /* gap: 10px; */
  padding: 0 15px;
  z-index: 98;
  left: 0;
  right: 0;
  background: #eef7ff;
  border-radius: 0 0 25px 25px !important;
  justify-content: space-between;
  /* transform: translateX(-50%); */
}

.hamburger-btn {
  margin-right: 1rem; 
  width: 60px;
}

.logo-container {
  width: 30px;
}

.main-header {
  border-radius: 0px;
  max-width: 1000px;
  width: 100%;
  /* width: calc(100% - 30px); */
  /* background: rgba(255, 255, 255, 1); */
  /* box-shadow: 0 0px 5px 0 #00000050; */
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}

.white-item-color {
  background-color: transparent !important;
}

.main-header nav {
  padding: 5px 0px;
  border-radius: 25px;
}

.slider-wrapper {
  margin-top: 3px;
  position: relative;
}

.slider-wrapper .rcs_sliderSet {
  box-shadow: 0 12px 12px rgba(0, 0, 0, 0.3) !important;
  border-radius: 20px !important;
}

.rcs_slideCon .itemWrapper::after {
  transform: scale(0.93) translateY(15px) translateZ(-40px);
  filter: blur(12px);
}

.tab-wrap div {
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
}

.font-active {
  color: #ac92ec;
}

.gallery-wrap {
  margin: -5px;
}

.gallery-wrap img {
  border-radius: 10px;
}

.border-active {
  border-bottom: 1px solid #ac92ec !important;
}

.card-style {
  overflow: hidden;
  border-radius: 22px;
  border: none;
  padding: 100px 0;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 20px 25px -5px, rgba(0, 0, 0, 0.02) 0px 10px 10px -5px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.deposit-container .deposit-wrap {
  padding: 80px 0 200px 0;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.reload-container .reload-wrap {
  padding: 80px 0 200px 0;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.reload-selet-item {
  width: fit-content;
}

.ant-drawer-content {
  background-color: transparent !important;
}

.ant-drawer-body {
  padding: 15px !important;
}

.notify-container {
  height: 100vh;
  top: 0;
  position: fixed;
  width: 100%;
  background-color: #dee2e6b3;
  z-index: 100;
  left: 0 !important;
}

.notify-container .card-style {
  margin: 0px 20px !important;
}

.dark-sidebar-container {
  background-color: #191a1ab0 !important;
}

.sidebar-wrapper {
  background-color: #fff;
  border-radius: 20px;
  height: calc(100vh - 30px);
}

.ant-drawer-body {
  background-color: white;
  border-radius: 15px 0 0 15px;
  padding: 10px !important;
}

.dark-sidebar-wrapper {
  background-color: #171819 !important;
}

.whatsapp-wrap {
  position: fixed;
  bottom: 120px;
  max-width: 470px;
  width: calc(100% - 30px);
}

.whatsapp-content {
  width: fit-content;
  margin: auto;
}

.styles-module_whatsappButton__tVits {
  position: relative !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.cc-register {
  width: 80px;
  padding-right: 0 !important;
}

.phone-register {
  padding-left: 0px !important;
}

.invalid-feedback {
  display: block;
  font-size: 13px;
}

.valid-feedback {
  display: none;
}

.dark-font-color {
  color: white !important;
}

.text-black {
  color: black !important;
}

.text-color {
  color: #212529 !important;
}

.resend-wrap {
  width: 100%;
  text-align: center;
}

.resend-code {
  color: #4a89dc;
}

.cursor-pointer {
  cursor: pointer;
}

.w-100px {
  width: 100px !important;
}

.w-full {
  width: 100%;
}

.w-fit {
  width: fit-content;
}

.min-h-full {
  min-height: 100vh;
}

.w-screen {
  width: 100vw;
}

.h-screen {
  height: 100vh;
}

.m-10 {
  margin: 10px;
}

.top-0 {
  top: 0px;
}

.left-0 {
  left: 0px;
}

.mr-10 {
  margin-right: 10px;
}

.p-5 {
  padding: 5px !important;
}

.pt-20 {
  padding-top: 20px;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-3 {
  padding-top: 3px !important;
}

.pt-1rem {
  padding-top: 1rem !important;
}

.rounded-15 {
  border-radius: 15px;
}

.zIndex1 {
  z-index: 1;
}

.zIndex100 {
  z-index: 1001;
}

.zIndex0 {
  z-index: 0;
}

.bg-01 {
  background: #00000040 !important;
}

.bg-white {
  background: white;
}

.text-20 {
  font-size: 20px !important;
}

.text-12 {
  font-size: 12px !important;
}

.d-flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.home-category-container {
  font-weight: bold;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  /* margin-top: 1rem; */
}

.home-category-container::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome browsers */
}

.tab-scroll-wrap {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  padding: 10px 0;
}

.tab-scroll-wrap::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome browsers */
}

.category-item {
  background: radial-gradient(transparent);
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.08) !important;
  width: 80px;
  height: 75px;
  display: inline;
  margin-right: 1rem;
  object-fit: cover;
  border-radius: 16px;
}

.special-label {
  display: none;
}

.flag-dropdown {
  border-radius: 0 !important;
}

.btn-auth {
  background-image: linear-gradient(to bottom, #A0D468, #8CC152);
  box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.1);
  color: white;
  padding: 8px 13px;
  border-radius: 12px;
  width: 100%;
  text-align: center;
}

.btn-login {
  background-image: linear-gradient(to bottom, #5D9CEC, #4A89DC);
  box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.1);
  color: white;
  padding: 8px 13px;
  border-radius: 12px;
  width: 100%;
  text-align: center;
}

.gap-20 {
  gap: 20px;
}

.font-bold {
  font-weight: bold;
}

.promo-item {
  box-shadow: rgba(0, 0, 0, 0.03) 0px 20px 25px -5px, rgba(0, 0, 0, 0.02) 0px 10px 10px -5px;
  border-radius: 22px;
  padding: 10px;
  margin-bottom: 20px;
}

.promo-item img {
  width: 100%;
  height: 100%;
  max-width: 700px;
  max-height: 466px;
  border-radius: 20px;
}

.banks-container {
  padding: 1rem;
  border: 1px solid #ced4da;
  border-radius: 10px;
}

.banks-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.banks-wrap .bank-item {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  border-radius: 8px;
  padding: 10px;
}

.banks-wrap .bank-item img {
  filter: grayscale(100%);
}

.selected-bank {
  color: #4a89dc !important;
  font-weight: bold;
}

.selected-bank img {
  filter: grayscale(0) !important;
}

.banks-info {
  margin-top: 30px;
  padding: 1rem;
  border: 1px solid #ced4da;
  border-radius: 10px;
  font-size: 10px;
  font-weight: bold;
  color: black !important;
}

.banks-info table {
  width: 100%;
}

.copy-btn {
  background: #4a89dc;
  color: white;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  text-shadow: black;
  padding: 0 5px;
  border-radius: 5px;
}

.reload-wrap select {
  height: 53px;
  margin-bottom: 27px;
}

.online-wrap select {
  height: 53px;
}

.withdraw-wrap select {
  height: 53px;
}

.withdraw-wrap input {
  height: 53px;
}

.position-relative {
  position: relative;
}

.position-fixed {
  position: fixed;
}

.position-absolute {
  position: absolute;
}

.max-btn {
  right: 10px;
  top: 18px;
  background-color: #4a89dc !important;
  color: white;
  padding: 0px 5px !important;
  border-radius: 5px;
}

.refresh-icon {
  width: fit-content;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 5px;
  padding: 0 5px;
}

.word-wrap {
  word-break: break-all;
}

.ant-upload-list .ant-upload-list-picture-card .ant-upload-list-item-container {
  margin-bottom: 0 !important;
}

.ant-upload.ant-upload-select {
  border-color: black !important;
}

.ot-custom-icon {
  background-image: url('../public/images/bank.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.mt-custom-icon {
  background-image: url('../public/images/transfer.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.gr-custom-icon {
  background-image: url('../public/images/grab.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.bo-custom-icon {
  background-image: url('../public/images/boost.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.tng-custom-icon {
  background-image: url('../public/images/tng.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.cash-custom-icon {
  background-image: url('../public/images/cash.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 0px;
}

.chips-custom-icon {
  background-image: url('../public/images/chips.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 0px;
}

.download-app-info {
  font-size: 15px;
}

.download-app-info .img-wrap {
  margin-bottom: 30px;
  border-radius: 10px;
  border: 4px solid #5d9cec;
  padding: 5px;
  overflow: hidden;
}

.download-app-info .img-wrap div {
  height: 130px;
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
}

.download-app-info .img-wrap img {}

.download-app-info .balance-wrap {
  margin-top: 30px;
  color: white;
  background-color: #5d9cec;
  font-weight: bold;
  text-align: center;
  border-radius: 20px 20px 0 0;
  font-size: 1.1em;
  margin-bottom: 20px;
}

.download-app-info .accountInfo-wrap {
  padding: 0 10px;
}

.download-app-info span {
  font-weight: bold;
}

.gallery-wrap .active {
  background: #4096ff;
  border-radius: 10px;
}

.playing-badge {
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  background: #4096ff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  text-align: center;
  color: white;
  font-weight: bold;
}

.loading-image {
  top: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  background: rgb(0 0 0 / 30%);
  color: white;
}

.card-active {
  border: 3px solid #4096ff;
}

.ant-upload-wrapper .ant-upload-select {
  overflow: hidden !important;
  vertical-align: bottom !important;
}

.bg-silver {
  background-color: silver;
}

.clipboard-wrap {
  padding: 5px 10px;
  width: 150px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 13px !important;
}

.download-apk-btn {
  border: 1px solid #4096ff;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  font-weight: bold;
  font-size: 12px;
}

.play-btn {
  border: 1px solid #8CC152;
  background-color: #8CC152;
  color: white;
  width: 100%;
  text-align: center;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  font-weight: bold;
  font-size: 15px;
}

.img-shadow {
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.login-modal-title {
  font-size: 20px;
  font-weight: bold;
  color: #318cd6;
  position: relative;
}

.balance-container {
  background-color: rgb(92, 149, 224);
  border-radius: 5px;
  align-items: center;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: flex-end;
}

.noticebar {
  font-size: 14px;
  color: #000;
  letter-spacing: 0.5px;
  background-size: 100% 100%;
  z-index: 2;
  /* position: relative; */
  overflow: hidden;
  bottom: 8px;
  font-weight: 500;
}

.avatar_info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.avatar_image {
  border-radius: 100%;
  border: 1px solid #2691f3;
}

.bank-account-img {
  padding: 0 !important;
  display: flex;
}

.bank-account-img input {
  height: 53px !important;
  padding: 0px 0px 0px 40px !important;
  border: none;
}

.custom-input-p {
  height: 53px !important;
  padding: 0px 15px 0px 15px !important;
}

.bank-account-img img {
  margin-right: 20px;
  margin-top: 6px;
  margin-left: auto;
}

.save-bank-icon {
  position: absolute;
  margin-top: 6px;
  margin-left: 150px;
}

.save-wrap .css-13cymwt-control {
  height: 53px !important;
  border-radius: 10px !important;
}

.save-wrap .css-t3ipsp-control {
  height: 53px !important;
  border-radius: 10px !important;
}

:where(.css-dev-only-do-not-override-w8mnev).ant-tabs .ant-tabs-tab+.ant-tabs-tab {
  margin: 0 0 0 0px;
}

:where(.css-dev-only-do-not-override-12upa3x).ant-tabs .ant-tabs-tab+.ant-tabs-tab {
  margin: 0 0 0 0px;
}

:where(.css-1wazalj).ant-tabs .ant-tabs-tab+.ant-tabs-tab {
  margin: 0 0 0 0px;
}
.select_bank{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d9d9d9;
  padding: 4px 11px;
  border-radius: 6px;
  font-size: 15px;
  margin-bottom: 1rem;
  margin-top: 6px;
}
.home-category-container .ant-tabs-nav{
  background-image: url(./images/tab_bg.png);
  background-size: 102% 113%;
    background-repeat: no-repeat;
    padding: 10px 7px;
    border-radius: 6px;
    overflow: hidden;
    background-position: -3px 0;
    margin-bottom: 0px !important;
}
 .home-category-container .ant-tabs-tab-active{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-image: linear-gradient(to bottom, #5D9CEC, #4A89DC) !important;
  border-radius: 6px;
}
.home-category-container .ant-tabs-tab-active > div > div{
  /* color: white !important;
  padding: 0 16px !important */
}
.home-category-container .ant-tabs-ink-bar-animated{
  display: none !important;
}
.custom_bg{
}
.ant-tabs-content-holder{
  background-color: #eff8ff ;
  padding: 0.625rem;
}
.home-category-container .ant-tabs-content-holder{
  padding: 0 !important;
  display: none;
}
.deposit-container .d_heading,
.withdraw-wrap .d_heading{
  background-color: rgb(14 165 233);
  width: fit-content;
  margin-left: 5px;
  padding: 2px 6px;
}

.category-game {
  display: flex;
  align-items: center;
  width: 85px; /* Adjust the width as needed */
  height: 30px;
  color: black;
  margin: 2px;
}

.category-image {
  width: 30px;
  height: 30px;
  margin: 5px;
}

@media (max-width: 767px) { /* Mobile view */
  .category-game {
    width: 52px; /* Adjust the width as needed */
    height: 20px;
    font-size: 12px;
    margin: 5px;
  }

  /* .category-image {
    width: 20px;
    height: 22px;
    margin: 0px;
  } */

  :where(.css-dev-only-do-not-override-w8mnev).ant-tabs .ant-tabs-tab+.ant-tabs-tab {
    margin: 0 0 0 0px;
  }

  :where(.css-dev-only-do-not-override-12upa3x).ant-tabs .ant-tabs-tab+.ant-tabs-tab {
    margin: 0 0 0 0px;
  }

  :where(.css-1wazalj).ant-tabs .ant-tabs-tab+.ant-tabs-tab {
    margin: 0 0 0 0px;
  }
}

.anticon svg {
  display: block;
}

.react-tel-input .flag {
  background: no-repeat;
  background-size: cover;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  background-image: url('../public/images/phone.png')
}

.react-tel-input .selected-flag {
  position: initial;
  width: 0px;
  height: 0px;
}

.react-tel-input .selected-flag .flag{
  position: absolute;
  top: 25%;
}

.react-tel-input .form-control{
  border: 1px solid black;
}



/* NoInternet.css */

.no-internet-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.no-internet-content {
  text-align: center;
  padding: 20px;
  background-color: #fff; /* Background color for the content */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 300px; /* Adjust the max-width as needed */
  width: 100%;
}

.no-internet-container h1 {
  color: #4c4c4c; /* Dark gray color for the heading */
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.no-internet-container p {
  color: #7d7d7d; /* Slightly lighter gray color for the text */
  font-size: 1.2rem;
}