.app-accounts-container {
  padding: 6rem 0;

  .account-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 1rem;
    border-bottom: 1px solid #ccc;
    padding-bottom: 1rem;

    .account-content {
      width: 100%;
      height: 100px;
      margin-left: 1rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      p {
        font-size: 1rem;
        width: 100%;
        font-weight: 500;
        margin-bottom: 0;
      }

      .pass {
        margin-top: 0;
        border: none;
        width: 70%;
        outline: none;
        background-color: #eee;
        margin-top: 4px;
        font-weight: 500;

        input {
          background-color: transparent;
        }
      }
    }

    h3 {
      width: 50%;
      text-align: right;
      font-weight: bold;
      font-size: 1.1rem;
    }

    h3 > span {
      font-size: 0.8rem;
      font-weight: 300;
    }

    img {
      width: 100px;
      height: 100px;
      border-radius: 1rem;
    }
  }
}

.ant-skeleton-title {
  height: 12px !important;
  margin: 4px 0 !important;
}

.ant-skeleton-paragraph {
  margin-block-start: 10px !important;
  li {
    height: 8px !important;
    margin: 4px 0 !important;
  }
}

.tx-history-container {
  padding-bottom: 5rem;

  .tx-history-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    h1 {
      font-weight: 500;
      color: black;
      font-size: 1.2rem;
    }
  }

  .ant-tabs-nav {
    background: white;
    border-radius: 8rem;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    border: none;
    overflow: hidden;
    padding: 4px;

    &::before {
      border-bottom: none;
    }

    .ant-tabs-nav-list {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      border: none;
    }

    .ant-tabs-tab {
      padding: 8px;
      border-radius: 8rem;
      margin: 0;
      flex-basis: 25%;
      justify-content: center;
      font-weight: 400;
      transition: .0s ease-in-out;
    }

    .ant-tabs-tab-active {
      background: linear-gradient(to bottom, #4096ff, #0874b8);
      display: flex;
      .ant-tabs-tab-btn {
        color: white;
        transition: .0s ease-in-out;
      }
    }
  }

  .ant-tabs-ink-bar {
    display: none;
  }

  .ant-tabs-content-holder {
    padding: 0;
    background: transparent;
  }
  .order-item {
    background-color: transparent;
    .filter {
      margin: 0;
      padding: 0;
    }
    .order-list-item {
      background: transparent;
    }
  }
}
.bank_acc button{
  padding:4px 15px;
  border-radius:6px; 
  font-size:14px;
  height: 32px;
  cursor: pointer;
  font-weight: 400;
}
.bank_acc .cancel_btn{
  color: #D9D9D9;
  border: 1px solid #D9D9D9;
}
.bank_acc .cancel_btn:hover{
  color: #4096FF;
  border: 1px solid #4096FF;
}

.started-game-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.started-game-footer {
  background: linear-gradient(to left, #ffffff, #a9deff);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  border-radius: 8px;
  width: 100%;
  max-width: 475px;
  border: 1px solid #0874b8;
  box-shadow: 0 0 6px 6px rgba(0, 0, 0, 0.06);
}

.started-game-context {
  display: flex;
  align-items: center;
}

.started-game-context h4 {
  font-weight: 500;
}

.started-game-img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 1rem;
}

.started-game-footer button {
  background: #0874b8;
  border: none;
  border-radius: 8px;
  color: #ffffff;
  padding: 4px;
}

.started-game-footer .provider-box {
  display: flex;
  align-items: center;
}

.provider-box p {
  margin-right: 4px;
  font-weight: 500;
  font-size: .8rem;
}

.provider-box .live-json {
  width: 40px;
}